import { useEffect, useRef } from 'react'
import { Marker, useMap } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  authSelectors,
  chatCredentialsSelectors,
  setIsSignIn,
  setPosts,
  setProfileItemId,
  setSelectedMarkerData,
  usersSelectors
} from 'store'
import { MAX_ZOOM_LEVEL, LOCATION_TYPE } from 'utils/constants'
import { createMarkerColor } from 'utils/helpers'
import { v4 as uuid } from 'uuid'
import { ImagesPng } from 'assets/images'

export const MarkerItem = ({
  item, geoLoc, profileAvatar
}) => {
  const { activeProfile } = useSelector(usersSelectors)
  const { isSignIn } = useSelector(authSelectors)
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)

  const map = useMap()

  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()

  const markerRef = useRef(null)
  // for debug
  const testAvatar = 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg'
  // console.log(markerAvatars)

  const liveLocCoord = [xmppLiveLocation?.lat, xmppLiveLocation?.lng]
  const iconMarker = createMarkerColor(
    dayjs(item?.last_accessed),
    profileAvatar ?? ImagesPng.DefaultImage,
    item?.is_friends
  )
  const id = item?.id
  const idParam = searchParams.get('id')

  setTimeout(() => {
    if (+idParam === id && markerRef.current) {
      dispatch(setProfileItemId(item.id))
      dispatch(setSelectedMarkerData(item))
    }
  }, 500)

  useEffect(() => {
    const marker = markerRef.current

    if (
      +idParam !== id
      && +localStorage.getItem('id') === id
      && markerRef.current
      && (
        localStorage.getItem('isNotifOpen') === null
        || localStorage.getItem('isNotifOpen') === 'false'
      )
    ) {
      try {
        document.querySelector('button[name="profile-pane-back-btn"]').click()
      } catch {
        //
      }
      setSearchParams({ id })
    } else if (
      isSignIn
      && activeProfile?.id === id
      && +idParam !== id
      && localStorage.getItem('id') === null
      && marker
    ) {
      // eslint-disable-next-line no-underscore-dangle
      map.setView(marker._latlng, MAX_ZOOM_LEVEL ?? 16)
      dispatch(setIsSignIn(false))
    }
  }, [])

  return (
    <Marker
      zIndexOffset={-99999}
      eventHandlers={{
        click: () => {
          dispatch(setPosts([]))

          dispatch(setProfileItemId(null))
          dispatch(setSelectedMarkerData(null))
          setSearchParams({ id })

          setTimeout(() => {
            dispatch(setProfileItemId(item.id))
            dispatch(setSelectedMarkerData(item))
          }, 0)
        }
      }}
      key={uuid()}
      position={
        geoLoc
        && Number.isNaN(xmppLiveLocation.lat) === false
        && item.location_type[LOCATION_TYPE.LiveLocation] === 0
          ? liveLocCoord
          : item?.coordinates
      }
      icon={iconMarker}
      ref={markerRef}
      title={item?.id}
    />
  )
}
