// Status
export const PENDING = 'PENDING'
export const SUCCEEDED = 'SUCCEEDED'
export const REJECTED = 'REJECTED'
// Auth Section
export const SIGN_UP = 'SIGN_UP'
export const SIGN_IN = 'SIGN_IN'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_VERIFY = 'FORGOT_PASSWORD_VERIFY'
export const VERIFY_LINK_PAGE = 'VERIFY_LINK_PAGE'
export const IS_VERIFY_PAGE = 'IS_VERIFY_PAGE'
// Validation
export const CHANGE_PASSWORD = 'changePassword'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const CURRENT_PASSWORD = 'currentPassword'
export const CONFIRM_PASSWORD = 'confirmPassword'
export const USER_NAME = 'userName'
export const USER_URL = 'userUrl'
// Map
export const ZOOM_LEVEL = 16
export const MAX_ZOOM_LEVEL = 16
export const UNSUPPORTED_MAX_ZOOM_LEVEL = 25
export const MAP_URL = process.env.REACT_APP_MAP_TYPE === 'stadia'
  ? 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
  : 'https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=' + process.env.REACT_APP_MAP_TILER_KEY
export const MAP_ATTRIBUTION = process.env.REACT_APP_MAP_TYPE === 'stadia'
  ? '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
  : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// User Section
export const MY_PROFILES = 'MY_PROFILES'
export const SINGLE_PROFILE = 'SINGLE_PROFILE'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const SETTINGS = 'SETTINGS'
export const PROFILE_BLOG = 'PROFILE_BLOG'
export const PROFILE_BLOG_COMMENT = 'PROFILE_BLOG_COMMENT'
// Notification Section
export const NotificationSections = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  BLOG_LIST: 'BLOG_LIST',
  BLOG_PREFERENCE: 'BLOG_PREFERENCE',
  BLOG_COMMENTS_FOR_POST: 'BLOG_COMMENTS_FOR_POST'
}
// Verify text
export const PASSWORD_VERIFY_TEXT = 'A verification link has been sent to your current email address. Please, click on the link to verify the email and create a new password. \n For MS Outlook, Yahoo and AOL email users, there is a high chance the mail will be tagged as spam. Kindly look for it in the spam section'
export const SIGN_UP_VERIFY_TEXT = 'A verification link has been sent to your current email address. Please, click on the link to verify the email and complete the registration \n process. For MS Outlook, Yahoo and AOL email users, there is a high chance the mail will be tagged as spam. Kindly look for it in the spam section'
// Verify page
export const VERIFY_PAGE = {
  ForgotPassword: 'Forgot Password',
  SignIn: 'Sign In',
  SignUp: 'Sign Up',
  MyProfiles: 'My Profiles',
  FriendsProfile: 'Friends Profile',
  EditProfile: 'Edit profile',
  Settings: 'Account Settings',
  ProfileBlog: 'Profile Blog',
  ProfileBlogComment: 'Profile Blog Comment'
}
// Filters type
export const FILTERS_TYPE = {
  Own: 'own',
  CodeWord: 'code_word',
  Affinities: 'affinities'
}
// Settings type
export const SETTINGS_TYPE = {
  Profile: 'profile',
  Email: 'email',
  Password: 'password',
  Notif: 'frequency'
}
// Marker position
export const INITIAL_POSITION = [40.7128, -74.006]
export const LOCATION_TYPE = {
  LiveLocation: 'Live Location',
  LiveApproximate: 'Live, Approximate',
  StaticLocation: 'Static Location'
}
// Notification Type
export const NOTIFICATION_TYPE = {
  Accept: 'Accept',
  Reject: 'Reject',
  View_Profile: 'View User Profile'
}

export const MODAL_SECTION = {
  RightSection: 'RightSection',
  LeftSection: 'LeftSection'
}

export const URLS = {
  Odysee: 'https://www.odysee.com/',
  Flote: 'https://flote.app/'
}

export const IS_FRIEND_STATUSES = {
  pending: 'pending',
  accepted: 'accepted',
  no: 'no'
}

export const NOTIFICATIONS_TITLE = {
  Connector: 'connector',
  Proximity: 'proximity',
  NewUsers: 'new_users',
  Concerts: 'concerts_and_events',
  CommunityBlogs: 'community_blogs',
  Advertisers: 'advertisers',
  ConnectionRequests: 'Connection Requests'
}

export const LOGOUT_ERR_MSG = [
  'Concurrent login is not allowed when using live location or live approximate',
  'This account will be unbanned on'
]

export const ERR_MSG_GEO_LOC = {
  0: 'Kindly allow Location Services if you want to use Live Location',
  1: 'Failed to obtain location. Kindly manually allow the location services in your browser and device settings if you want to use Live Location',
  2: 'Kindly allow Location Services as you are using Live Location'
}

export const ACCEPTED_IMG_LIST = 'image/jpeg, image/jpg, image/png, image/bmp, image/webp'
