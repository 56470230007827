import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMarkers, getNotificationMessage, setFilters,
  usersSelectors, chatCredentialsSelectors, filterMarkers
} from 'store'
import { FILTERS_TYPE, PENDING, REJECTED } from 'utils/constants'
import { v4 as uuid } from 'uuid'

import { SearchBarInput } from 'components/SearchContainer/SearchBarInput'
import { isAuth } from 'utils/helpers'

export const SearchContainer = () => {
  const {
    searchBar, filters, getSearchParamsStatus, activeProfile
  } = useSelector(usersSelectors)
  const { isXmppModalSpinnerHidden } = useSelector(chatCredentialsSelectors)
  const [affinities, setAffinities] = useState([])
  const [codeWords, setCodeWords] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isCodeWordSelect, setIsCodeWordSelect] = useState(false)

  let tempRecentSearch = JSON.parse(localStorage.getItem('recentSearches'))
  const [recentSearchesList, setRecentSearchesList] = useState(tempRecentSearch)
  const [isAffinityReplace, setIsAffinityReplace] = useState(false)

  if (!localStorage.getItem('recentSearches')) {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearchesList))
  }

  const handleChangeValue = () => {
    if (searchValue.length > 0 && searchValue[0] === '#') {
      return searchValue.substring(1)
    }
    return searchValue
  }

  const [searchValueArray, setSearchValueArray] = useState([])

  const dispatch = useDispatch()

  const isLoading = getSearchParamsStatus === PENDING
   || (activeProfile?.id && !isXmppModalSpinnerHidden)

  const handleChangeInput = (e) => {
    const searchValueToLower = searchValue.toLowerCase()
    let isAffinity = []

    if (isCodeWordSelect === false) {
      isAffinity = affinities.filter((item) => item.title.toLowerCase() === searchValueToLower)
    }

    let isCodeWord = []
    if (isAffinity.length === 0) {
      isCodeWord = codeWords.filter((item) => item.title === handleChangeValue())
    }

    let isOwnWord = []
    if (isAffinity.length === 0 && isCodeWord.length === 0) {
      isOwnWord = handleChangeValue()
    }

    if (e.keyCode === 13 && searchValue.length > 0) {
      // console.log(searchValueArray)
      const shortVal = searchValue.toLowerCase()
      const repeatedWord = searchValueArray.filter(
        (item) => {
          if (shortVal.includes('#')) {
            return item.title.toLowerCase().slice(1) === shortVal
          }
          if (shortVal.includes('#') === false && isCodeWordSelect === true) {
            let tempShortVal = '#' + searchValue.toLowerCase()
            return item.title.toLowerCase() === tempShortVal
          }

          return item.title.toLowerCase() === shortVal
        }
      )
      const searchCodeWordVal = searchValue.includes('#') ? searchValue.replace(/(?!^)#/g, '') : '#' + searchValue
      const repeatedCodeword = searchValueArray.filter(
        (item) => item?.title === isCodeWord[0]?.title || item?.title === searchCodeWordVal
      )

      const repeatedOwnWord = searchValueArray.filter(
        (item) => item?.title === isOwnWord
      )

      let itemTitle = ''
      let itemId = 0
      let type = ''
      if (isCodeWord.length > 0) {
        itemTitle = searchValue.includes('#') ? searchValue.replace(/(?!^)#/g, '') : '#' + searchValue
        itemId = uuid()
        type = FILTERS_TYPE.CodeWord
      } else if (isAffinity.length > 0 && isCodeWordSelect === false) {
        itemTitle = isAffinity[0].title
        itemId = isAffinity[0].id
        type = FILTERS_TYPE.Affinities
      } else {
        itemTitle = searchValue.replace(/(?!^)#/g, '')
        itemId = uuid()
        type = FILTERS_TYPE.Own
      }

      if (repeatedWord.length === 0
        && repeatedCodeword?.length === 0
        && repeatedOwnWord?.length === 0
        && !isLoading) {
        setSearchValueArray([...searchValueArray, { title: itemTitle, id: itemId, type }])
        setSearchValue('')

        if (type === 'affinities') {
          if (recentSearchesList.includes(itemTitle)) {
            let tempRecent = recentSearchesList
            let index = tempRecent.indexOf(itemTitle)
            tempRecent.splice(index, 1)
            tempRecent.unshift(itemTitle)
            setRecentSearchesList(tempRecent)
            setIsAffinityReplace(!isAffinityReplace)
          } else if (recentSearchesList.length < 5) {
            setRecentSearchesList(() => [itemTitle, ...recentSearchesList])
          } else if (recentSearchesList.length === 5) {
            let tempRecent = recentSearchesList
            tempRecent.pop()
            tempRecent.unshift(itemTitle)
            setRecentSearchesList(tempRecent)
            setIsAffinityReplace(!isAffinityReplace)
          }
        }
        // zoom control, filter container and marker counter
        setTimeout(() => {
          const ZoomCont = document.querySelector('.leaflet-control-zoom')
          const filterData = document.querySelector('.filter-data')
          const markerCountBox = document.querySelector('.marker-count')
          const markerPopupCont = document.querySelector('.leaflet-popup')
          ZoomCont.style.opacity = 1
          ZoomCont.style.pointerEvents = 'auto'
          filterData.style.opacity = 1
          filterData.style.pointerEvents = 'auto'
          if (markerPopupCont) {
            markerCountBox.style.opacity = 0
            markerCountBox.style.pointerEvents = 'none'
          } else {
            markerCountBox.style.opacity = 1
            markerCountBox.style.pointerEvents = 'auto'
          }
        }, 1000)
      } else if (repeatedWord?.length !== 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Affinity already exist!'
        }))
        setSearchValue('')
      } else if (repeatedCodeword?.length !== 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Code Word already exist!'
        }))
        setSearchValue('')
      } else if (repeatedOwnWord?.length !== 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Search Input already exist!'
        }))
        setSearchValue('')
      }
    }
  }

  const addFilters = ({ title, type, id }) => {
    const repeatedWord = searchValueArray.filter(
      (item) => item.type === type && item.title === title
    )
    if (repeatedWord.length === 0) {
      setSearchValueArray([...searchValueArray, { title, id, type }])
      setSearchValue('')
      // zoom control, filter container and marker counter
      setTimeout(() => {
        const ZoomCont = document.querySelector('.leaflet-control-zoom')
        const filterData = document.querySelector('.filter-data')
        const markerCountBox = document.querySelector('.marker-count')
        const markerPopupCont = document.querySelector('.leaflet-popup')
        ZoomCont.style.opacity = 1
        ZoomCont.style.pointerEvents = 'auto'
        filterData.style.opacity = 1
        filterData.style.pointerEvents = 'auto'
        if (markerPopupCont) {
          markerCountBox.style.opacity = 0
          markerCountBox.style.pointerEvents = 'none'
        } else {
          markerCountBox.style.opacity = 1
          markerCountBox.style.pointerEvents = 'auto'
        }
      }, 1000)
    } else if (repeatedWord.length !== 0) {
      dispatch(getNotificationMessage({
        type: REJECTED,
        message: 'Affinity already exist!'
      }))
      setSearchValue('')
    }
  }

  useEffect(() => {
    let searchAffinities = []
    let searchCodewords = []

    searchValueArray.map((item) => {
      if (item.type === FILTERS_TYPE.Affinities) {
        searchAffinities.push(item.title)
        return `affinities[]=${item.title}`
      }

      const itemTitle = item.title.replace('#', '')
      searchCodewords.push(itemTitle)
      return `code_words[]=${itemTitle}`
    })

    // console.log(searchValueArray)

    dispatch(setFilters(searchValueArray))
    if (isAuth()) {
      setTimeout(() => {
        document.querySelector('div.leaflet-container').click()
        dispatch(filterMarkers({
          affinities: searchAffinities,
          codewords: searchCodewords
        }))
        if (searchValueArray.length > 0) {
          localStorage.setItem('is-filtered', true)
        } else if (searchValueArray.length === 0) {
          localStorage.setItem('is-filtered', false)
        }
      }, 0)
    }
  }, [searchValueArray])

  useEffect(() => {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearchesList))
  }, [recentSearchesList, isAffinityReplace])

  useEffect(() => {
    setSearchValueArray(filters)
  }, [filters])

  useEffect(() => {
    if (isAuth() === false) {
      setRecentSearchesList([])
    }
  }, [isAuth()])

  useEffect(() => {
    if (affinities.length === 0 && searchBar.affinities !== undefined) {
      setAffinities(searchBar.affinities)
    }

    if (codeWords.length === 0 && searchBar.code_words !== undefined) {
      setCodeWords(searchBar.code_words)
    }
  }, [searchBar])

  return (
    <SearchBarInput
      isLoading={isLoading}
      addFilters={addFilters}
      affinities={affinities}
      codeWords={codeWords}
      value={searchValue}
      setIsCodeWordSelect={setIsCodeWordSelect}
      isCodeWordSelect={isCodeWordSelect}
      setRecentSearchesList={setRecentSearchesList}
      recentSearchesList={recentSearchesList}
      setIsAffinityReplace={setIsAffinityReplace}
      isAffinityReplace={isAffinityReplace}
      onKeyDown={(e) => {
        if (filters.length < 3) {
          handleChangeInput(e)
        } else {
          dispatch(getNotificationMessage({
            type: REJECTED,
            message: 'You already entered the maximum number of filters.'
          }))
        }
      }}
      onChange={setSearchValue}
      width="330px"
      height="32px"
      name="search"
    />
  )
}
