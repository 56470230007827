import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@chakra-ui/react'
import {
  getPosts,
  handleModalTitle,
  setPosts,
  usersSelectors
} from 'store'
import { UserInformation } from 'components/UserSection/ProfileBlog/UserInformation'
import { ProfileBlogForm } from 'components/UserSection/ProfileBlog/ProfileBlogForm'
import { BlogList } from 'components/UserSection/ProfileBlog/BlogList'
import { VERIFY_PAGE } from 'utils/constants'

export const ProfileBlog = ({
  selectedUser,
  affinityList
}) => {
  const dispatch = useDispatch()
  const [reload, setReload] = useState(0)
  const [editPostData, setEditPostData] = useState({})

  const {
    posts,
    getPostsStatus,
    postPostsStatus,
    putPostsStatus,
    addImagePostsStatus,
    blogParent
  } = useSelector(usersSelectors)

  const {
    id,
    full_name,
    profile_picture,
    banner
  } = selectedUser

  const fetchPosts = (lastItemId) => {
    dispatch(getPosts({
      type: 'profile',
      id,
      ...(lastItemId === undefined ? {} : { lastItemId })
    }))
  }

  useEffect(() => {
    dispatch(setPosts([]))
    fetchPosts()

    setReload(Math.random())
  }, [selectedUser])

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.ProfileBlog))
  }, [])

  return (
    <Stack>
      {reload && (
        <Stack
          direction="column"
          gap="0"
          marginBottom="-25px"
        >
          <UserInformation
            profilePicture={profile_picture}
            fullName={full_name}
            coverPicture={banner}
          />
          <ProfileBlogForm
            profileId={id}
            profilePicture={profile_picture}
            editPostData={editPostData}
            setEditPostData={setEditPostData}
            postPostsStatus={postPostsStatus}
            putPostsStatus={putPostsStatus}
            addImagePostsStatus={addImagePostsStatus}
            affinityList={affinityList}
            getPostsStatus={getPostsStatus}
          />
          <BlogList
            fetchPosts={fetchPosts}
            profilePicture={profile_picture}
            fullName={full_name}
            posts={posts}
            setEditPostData={setEditPostData}
            getPostsStatus={getPostsStatus}
            blogParent={blogParent}
          />
        </Stack>
      )}
    </Stack>
  )
}
