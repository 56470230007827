import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box, Spinner, Stack, Text,
  useOutsideClick
} from '@chakra-ui/react'
import { AddSearchIcon } from 'assets/icons'
import { usersSelectors } from 'store'
import { FILTERS_TYPE } from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { StyledInput } from 'components/SearchContainer/SearchBarInput/styled'

export const SearchBarInput = ({
  width = '100%',
  height = '100%',
  onKeyDown,
  onChange,
  value,
  affinities,
  codeWords,
  addFilters,
  isLoading,
  name = 'search',
  setIsCodeWordSelect,
  isCodeWordSelect,
  recentSearchesList,
  setRecentSearchesList,
  setIsAffinityReplace,
  isAffinityReplace
}) => {
  const affinitiesListRef = useRef(null)
  const inputWrapperRef = useRef(null)
  const inputRef = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(true)
  const { filters } = useSelector(usersSelectors)
  const [affinityList, setAffinityList] = useState('')
  const [recentSearchList, setRecentSearchList] = useState('')

  useOutsideClick({
    ref: inputRef,
    handler: () => {
      setIsPopupOpen(false)
    }
  })

  const getAffinityList = (searchValue = '') => {
    let tempAffinities = affinities

    if (searchValue !== '') {
      const tempSearchValue = searchValue.toLowerCase()

      tempAffinities = tempAffinities.filter((item) => {
        const title = item.title.toLowerCase()

        return tempSearchValue === title.substring(0, tempSearchValue.length)
      })
    }

    setAffinityList(tempAffinities.map((item) => (
      <Stack
        cursor="pointer"
        key={uuid()}
        direction="row"
        spacing="10px"
        mr="5px !important"
        p="2px 14px"
        borderRadius="5px"
        bg={COLORS.gold}
        width="max-content"
        alignItems="center"
        onClick={() => {
          if (filters.length < 3) {
            addFilters({
              title: item.title,
              id: item.id,
              type: FILTERS_TYPE.Affinities
            })
            if (recentSearchesList.includes(item.title)) {
              let tempRecent = recentSearchesList
              let index = tempRecent.indexOf(item.title)
              tempRecent.splice(index, 1)
              tempRecent.unshift(item.title)
              setRecentSearchesList(tempRecent)
              setIsAffinityReplace(!isAffinityReplace)
            } else if (recentSearchesList.length < 5) {
              setRecentSearchesList(() => [item.title, ...recentSearchesList])
            } else if (recentSearchesList.length === 5) {
              let tempRecent = recentSearchesList
              tempRecent.pop()
              tempRecent.unshift(item.title)
              setRecentSearchesList(tempRecent)
              setIsAffinityReplace(!isAffinityReplace)
            }
            // zoom control, filter container and marker counter
            setTimeout(() => {
              const ZoomCont = document.querySelector('.leaflet-control-zoom')
              const filterData = document.querySelector('.filter-data')
              const markerCountBox = document.querySelector('.marker-count')
              const markerPopupCont = document.querySelector('.leaflet-popup')
              ZoomCont.style.opacity = 1
              ZoomCont.style.pointerEvents = 'auto'
              filterData.style.opacity = 1
              filterData.style.pointerEvents = 'auto'
              if (markerPopupCont) {
                markerCountBox.style.opacity = 0
                markerCountBox.style.pointerEvents = 'none'
              } else {
                markerCountBox.style.opacity = 1
                markerCountBox.style.pointerEvents = 'auto'
              }
            }, 1000)
          }
        }}
      >
        <Text cursor="pointer">{item.title}</Text>
      </Stack>
    )))
  }

  const getRecentSearches = () => {
    let recentSearch = Object.values(JSON.parse(localStorage.getItem('recentSearches')))
    let tempAffinities = affinities

    if (recentSearch.length !== 0) {
      tempAffinities = tempAffinities.filter((item) => {
        const title = item.title.toLowerCase()
        let recentCounter = 0

        recentSearch.forEach((items) => {
          // console.log(title !== items.toLowerCase())
          if (title === items.toLowerCase()) {
            recentCounter += 1
          }
        })

        if (recentCounter > 0) {
          return true
        }
        return false
      })
    } else if (recentSearch.length === 0) {
      tempAffinities = []
    }

    setRecentSearchList(tempAffinities.map((item) => (
      <Stack
        cursor="pointer"
        key={uuid()}
        direction="row"
        spacing="10px"
        mr="5px !important"
        p="2px 14px"
        borderRadius="5px"
        // height="28px"
        bg={COLORS.gold}
        width="max-content"
        alignItems="center"
        onClick={() => {
          if (filters.length < 3) {
            addFilters({
              title: item.title,
              id: item.id,
              type: FILTERS_TYPE.Affinities
            })
            if (recentSearchesList.includes(item.title)) {
              let tempRecent = recentSearchesList
              let index = tempRecent.indexOf(item.title)
              tempRecent.splice(index, 1)
              tempRecent.unshift(item.title)
              setRecentSearchesList(tempRecent)
              setIsAffinityReplace(!isAffinityReplace)
            } else if (recentSearchesList.length < 5) {
              setRecentSearchesList(() => [item.title, ...recentSearchesList])
            } else if (recentSearchesList.length === 5) {
              let tempRecent = recentSearchesList
              tempRecent.pop()
              tempRecent.unshift(item.title)
              setRecentSearchesList(tempRecent)
              setIsAffinityReplace(!isAffinityReplace)
            }
            // zoom control, filter container and marker counter
            setTimeout(() => {
              const ZoomCont = document.querySelector('.leaflet-control-zoom')
              const filterData = document.querySelector('.filter-data')
              const markerCountBox = document.querySelector('.marker-count')
              const markerPopupCont = document.querySelector('.leaflet-popup')
              ZoomCont.style.opacity = 1
              ZoomCont.style.pointerEvents = 'auto'
              filterData.style.opacity = 1
              filterData.style.pointerEvents = 'auto'
              if (markerPopupCont) {
                markerCountBox.style.opacity = 0
                markerCountBox.style.pointerEvents = 'none'
              } else {
                markerCountBox.style.opacity = 1
                markerCountBox.style.pointerEvents = 'auto'
              }
            }, 1000)
          }
        }}
      >
        <Text cursor="pointer">{item.title}</Text>
      </Stack>
    )))
  }

  const handleCodeWordSelect = (e) => {
    if (e === false) {
      setIsCodeWordSelect(true)
    } else if (e === true) {
      setIsCodeWordSelect(false)
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        affinitiesListRef.current
        && !affinitiesListRef.current.contains(e.target)
        && !inputWrapperRef.current.contains(e.target)
      ) {
        setIsPopupOpen(false)
      }
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#once
    // If {once: true}, the listener would be automatically removed when invoked.
    document.body.addEventListener('mousedown', handleOutsideClick, { once: true })
  }, [isPopupOpen])

  useEffect(() => {
    getAffinityList()
  }, [affinities])

  useEffect(() => {
    onChange('')
  }, [filters])

  return (
    <Stack ref={inputRef} position="relative" direction="column">
      <Stack ref={inputWrapperRef} spacing={0} direction="row">
        <div className="code-word-toggle-container">
          <label style={{ cursor: 'pointer' }} htmlFor="code-word-toggle-input">
            <input type="checkbox" id="code-word-toggle-input" onChange={() => handleCodeWordSelect(isCodeWordSelect)} />
            <div className="toggle-for-code-word">
              <span>#</span>
            </div>
          </label>
        </div>
        <StyledInput
          type="search"
          maxLength="30"
          onFocus={() => setIsPopupOpen(true)}
          value={value}
          height={height}
          title="search-input"
          width={width}
          onKeyDown={(e) => {
            if (e.target.value[0] === '#') {
              setIsPopupOpen(false)
            } else {
              setIsPopupOpen(true)
            }

            onKeyDown(e)
          }}
          onChange={(e) => {
            if (e.target.value[0] === '#') {
              setIsPopupOpen(false)
            } else {
              getAffinityList(e.target.value)
              getRecentSearches()
              setIsPopupOpen(true)
            }

            onChange(e.target.value)
          }}
          name={name}
        />

        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          paddingRight="12px"
          alignItems="center"
          borderEndRadius="5px"
          background="white"
          cursor="pointer"
          onClick={() => {
            onKeyDown(new KeyboardEvent('keydown', {
              key: 'Enter',
              keyCode: 13,
              code: 'Enter',
              which: 13,
              bubbles: true,
              cancelable: true
            }))
          }}
        >
          <Stack>{isLoading ? <Spinner /> : <AddSearchIcon />}</Stack>
        </Box>
      </Stack>

      {(codeWords.length > 0 || affinities.length > 0) && value.length > 0 && isPopupOpen
        && isCodeWordSelect === false ? (
          <Stack
            ref={affinitiesListRef}
            width="100%"
            height="max-content"
            maxHeight="500px"
            left="0px"
            top="30px"
            position="absolute"
            bg="white"
            zIndex={9999}
            borderBottomRadius="5px"
          >

            <Stack
              p="15px"
              gap="5px"
              direction="row"
              width="100%"
              // paddingBottom="65px"
              spacing={0}
              flexWrap="wrap"
              data-name="recentSearchResultList"
              borderBottom="1px solid"
              borderColor="blackAlpha.500"
              overflow="auto"
            >
              <Text>
                Recent
              </Text>
              {recentSearchesList ? recentSearchList : null}
            </Stack>
            <Stack
              p="15px"
              overflow="auto"
              gap="5px"
              direction="row"
              maxHeight="300px"
              width="100%"
              spacing={0}
              flexWrap="wrap"
              data-name="searchResultList"
            >
              { affinityList }
            </Stack>
            <Box
              position="absolute"
              bottom="-25px"
              background="white"
              width="100%"
              padding="2px"
              zIndex={99}
              borderBottomRadius="5px"
              textAlign="center"
              fontSize="14px"
            >
              Please, select the affinities from the list above.
            </Box>
          </Stack>
        ) : null}
    </Stack>
  )
}
