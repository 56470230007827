import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box, Stack, Spinner, Text, Center
} from '@chakra-ui/react'
import { SingleButton } from 'common/Buttons'
import { emailVerify, authSelectors } from 'store'
import { getUrlParams, isAuth } from 'utils/helpers'
import { PENDING } from 'utils/constants'

export const EmailVerifyPage = () => {
  const navigate = useNavigate()
  const { isAlreadyVerified, emailVerifyStatus } = useSelector(authSelectors)

  const dispatch = useDispatch()

  const handleVerificationMessage = () => {
    if (isAlreadyVerified === true) {
      if (getUrlParams('email', 'purpose')[1] === 'verify new email') {
        return (
          <>
            <Text fontSize="24px" fontWeight={600}>
              Your verification link has been expired or used.
            </Text>
            <Text fontSize="16px" fontWeight={400}>
              Kindly return to continue
            </Text>
          </>
        )
      }
      return (
        <>
          <Text fontSize="24px" fontWeight={600}>
            Your verification link has been expired or used.
          </Text>
          <Text fontSize="16px" fontWeight={400}>
            Kindly return to the sign in page to start or redo the registration.
          </Text>
        </>
      )
    }
    return (
      <>
        <Text fontSize="24px" fontWeight={600}>
          Your Email address has been successfully verified!
        </Text>
        <Text fontSize="16px" fontWeight={400}>
          You can now sign in with your new account.
        </Text>
      </>
    )
  }

  const handleButtonText = () => {
    if (isAuth()) {
      return 'Return to website'
    }
    return 'Go to sign in'
  }

  useEffect(() => {
    if (
      getUrlParams('email', 'token')[0]
      && getUrlParams('email', 'token')[1]
    ) {
      const body = {
        email: getUrlParams('email', 'token')[0],
        token: getUrlParams('email', 'token')[1],
        purpose: getUrlParams('email', 'purpose')[1]
      }
      dispatch(emailVerify(body))
    }
  }, [])

  return (
    <Box h="100vh" w="100%">
      <Stack
        spacing="32px"
        sx={{
          position: 'absolute',
          top: '35%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}
      >
        {emailVerifyStatus === PENDING
          ? (
            <Center>
              <Spinner
                size="xl"
              />
            </Center>
          )
          : handleVerificationMessage()}

        <SingleButton
          customStyled={{
            width: '320px',
            margin: '100px auto 0',
            fontSize: '20px',
            fontWeight: 400
          }}
          click={() => {
            navigate('/')
          }}
          isLoading={false}
          text={handleButtonText()}
        />
      </Stack>
    </Box>
  )
}
